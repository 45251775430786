
import request from '@/utils/request'

//任务详情
export function taskDetail(params) {
  return request({
    url: '/groupRental/taskDetail',
    method: 'get',
    params:params
  })
}

//任务流水
export function taskFlow(params) {
  return request({
    url: '/groupRental/taskLog',
    method: 'get',
    params:params
  })
}


//流水导出
export function taskExport(data) {
	return request({
		url: '/groupRental/exportList',
		method: 'post',
		responseType: "blob",
		params: data
	})
}



// 问题相关----
// 问题列表
export function problemPage(params) {
  return request({
    url: '/groupRental/problemPage',
    method: 'get',
    params:params
  })
}


//问题统计
export function problemTotal(params) {
  return request({
    url: '/groupRental/problem',
    method: 'get',
    params:params
  })
}

//问题处理
export function processProblem(params) {
  return request({
    url: '/groupRental/processProblem',
    method: 'get',
    params:params
  })
}
//批量处理
export function processProblems(params) {
  return request({
    url: '/groupRental/processProblems',
    method: 'get',
    params:params
  })
}

//问题详情
export function problemDetail(params) {
  return request({
    url: '/groupRental/problemDetail',
    method: 'get',
    params:params
  })
}




//审核列表----
//审核列表
export function changePeriodPage(params) {
  return request({
    url: '/groupRental/changePeriodPage',
    method: 'get',
    params:params
  })
}

//审核
export function changePeriodAudit(params) {
  return request({
    url: '/groupRental/changePeriodAudit',
    method: 'get',
    params:params
  })
}

//审核列表
export function auditLogPage(params) {
  return request({
    url: '/groupRental/auditLogPage',
    method: 'get',
    params: params
  })
}