//告警状态
const warnStatusOptions = [{
  label: '正常',
  value: 0
},{
  label: '超时',
  value: 1
},{
  label: '终止',
  value: 2
}]

//完成状态
const periodStatusOptions = [{
  label: '未完成',
  value: 0
},{
  label: '已完成',
  value: 1
}]

//问题状态
const problemStatusOptions = [{
  label: '待处理',
  value: 0
},{
  label: '已处理',
  value: 1
}]

//认领状态
const claimStatusOptions = [{
  label: '未认领',
  value: 0
},{
  label: '已认领',
  value: 1
}]

//审核状态
const checkStatusOptions = [{
  label: '提交审核',
  value: 0
},{
  label: '审核通过',
  value: 1
},{
  label: '审核未通过',
  value: 2
}]


//巡防周期
const periodOptions = [{
  label: '按周',
  tip: '告警时间 周四',
  value: 0
},{
  label: '按月',
  tip: '告警时间 每月20号',
  value: 1
},{
  label: '按季度',
  tip: '告警时间 每季度最后一个月',
  value: 2
},{
  label: '按年',
  tip: '告警时间 每年最后以一个月',
  value: 3
}]


//操作类型
const operateTypeOptions = [{
  label: '新增巡防任务',
  value: 0
},{
  label: '删除巡防任务',
  value: 1
}]



export {
  warnStatusOptions,
  periodStatusOptions,
  problemStatusOptions,
  checkStatusOptions,
  periodOptions,
  operateTypeOptions,
  claimStatusOptions
}
